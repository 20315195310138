import Fuse from 'fuse.js';
import Store from 'store';
import vtxDomReady from './common/helpers';
import TomSelect from 'tom-select/dist/js/tom-select.base.min.js';

const GlobalSearch = {
  searchOptions, // eslint-disable-line
  tomSelectLookup: [],
  inputSelector: '.wrapper-search-autocomplete input.search-field',
  submitSelector: '.wrapper-search-autocomplete button[type=submit]',
  baseUrl: `${location.protocol}//${location.host}`,
  fuseOptions: {
    keys: [
      {
        name: 'Title',
        weight: 0.5,
      },
      {
        name: 'Tags',
        weight: 0.1,
      },
      {
        name: 'ManualTags',
        weight: 0.6,
      },
    ],
    minMatchCharLength: 2,
    threshold: 0.2,
    distance: 500,
  },
  init() {
    let storeSearchIndexVersion = Store.get(
      `searchIndexVersion_${this.searchOptions.language}`
    );

    if (
      'undefined' !== typeof storeSearchIndexVersion &&
      this.searchOptions.autocompleteSearchVersion === storeSearchIndexVersion
    ) {
      this.jsonIndex = Store.get(
        `searchJsonIndex_${this.searchOptions.language}`
      );
      this.fuseIndex = Fuse.parseIndex(
        Store.get(`searchIndex_${this.searchOptions.language}`)
      );
      this.initFuseSearchEngine();
    } else {
      fetch(`/wp-json/search/index/${this.searchOptions.language}`)
        .then((response) => response.json())
        .then((jsonIndex) => {
          this.jsonIndex = jsonIndex;
        })
        .then(() => {
          this.fuseIndex = Fuse.createIndex(
            this.fuseOptions.keys,
            this.jsonIndex
          );
        })
        .then(() => {
          this.initFuseSearchEngine();
        })
        .then(() => {
          Store.set(
            `searchIndexVersion_${this.searchOptions.language}`,
            this.searchOptions.autocompleteSearchVersion
          );
          Store.set(
            `searchJsonIndex_${this.searchOptions.language}`,
            this.jsonIndex
          );
          Store.set(
            `searchIndex_${this.searchOptions.language}`,
            this.fuseIndex
          );
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    }
  },
  initFuseSearchEngine() {
    if (this.jsonIndex && this.fuseIndex) {
      this.fuseEngine = new Fuse(
        this.jsonIndex,
        this.fuseOptions,
        this.fuseIndex
      );
      this.initSearchFields();
    }
  },
  initSearchFields() {
    let urlLanguageSuffix = '';
    if (this.searchOptions.languageSuffix) {
      urlLanguageSuffix = this.searchOptions.languageSuffix + '/';
    }
    document.querySelectorAll(this.inputSelector).forEach((searchInput) => {
      let currentTomSelect = new TomSelect(searchInput, {
        maxItems: 1,
        maxOptions: 5,
        valueField: 'url',
        labelField: 'Title',
        searchField: 'Title',
        optionClass: 'search-dropdown__item',
        itemClass: 'search-field__item',
        create: true,
        createOnBlur: true,
        persist: false,
        closeAfterSelect: true,
        addPrecedence: true, //"Search ###" option is selected by default
        load: (query, callback) => {
          let fuseResultsToReturn = [];
          console.log('Searching for: ', query);

          if (query.length < 2) {
            callback(fuseResultsToReturn);
          }

          let fuseResults = this.fuseEngine.search(query);

          fuseResults.forEach((fuseResult) => {
            fuseResult.item.url = `${this.baseUrl}/${urlLanguageSuffix}?page_id=${fuseResult.item.ID}`;
            fuseResultsToReturn.push(fuseResult.item);
          });
          callback(fuseResultsToReturn);
        },
        onItemAdd: (value) => {
          let redirectUrl = '';
          if (value.indexOf('http') === 0) {
            redirectUrl = value;
            window.location.href = redirectUrl;
          } else {
            redirectUrl = `${this.baseUrl}/${urlLanguageSuffix}?s=${value}`;
            document.querySelectorAll(this.submitSelector).forEach((submit) => {
              submit.addEventListener('click', function () {
                window.location.href = redirectUrl;
              });
            });
          }
        },
        onDropdownClose: () => {
          currentTomSelect.clearOptions();
        },
        onFocus: () => {
          currentTomSelect.clear();
          currentTomSelect.clearOptions();
        },
        render: {
          option_create: (data, escape) => {
            return `<div class="create search-dropdown__item search-dropdown__item--find">${
              this.searchOptions.searchQueryLabel
            } <strong>${escape(data.input)}</strong>&hellip;</div>`;
          },
          no_results: () => {
            return `<div class="no-results search-dropdown__item">${this.searchOptions.noSuggestionFoundLabel}</div>`;
          },
        },
      });
      this.tomSelectLookup.push(currentTomSelect);
    });
  },
};

vtxDomReady(() => {
  GlobalSearch.init();
});
